/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        $(document).ready(function() {
          $('select').niceSelect();
        });

        // jQuery('.vc_tta-panel-title>a').on('click', function(e){
        //     console.log();
        //     jQuery(this).closest('.vc_tta-panel').removeClass('vc_active')
        //   });



        $('#nav-icon1').on('click', function(){
          console.log('open');
          $('#nav-icon1').toggleClass('open');
          $('.mobile-menu').toggleClass('mobile-menu-active');
          $('body').toggleClass('stop-scrolling');
          $('.nav-mobile').toggleClass('open');


          if($('body').hasClass('stop-scrolling')){
            $('body').bind('touchmove', function(e){e.preventDefault()})
          }else{
            $('body').unbind('touchmove');
          }
        });

        $('input[name=productVersion]').click(function(e){
          var selected = $('input[name=productVersion]:checked').val();
          console.log($('input[name=productVersion]:checked').val());
          $('.product-version-content-wrapper').removeClass('show');

          $('#'+selected).addClass('show');

        });


        $('.search-icon.menu-item').on('click',function(e){
            e.preventDefault();

            $('.search-slider-wrapper').toggleClass('active');
            // $('header').addClass('scroll-active');
        });

        $('.nav-mobile li.menu-item-has-children > .mobile-menu-child-toggle').click(function() {
          var thisRound = Math.random();
          $(this).parent().toggleClass('show-child-menu').attr('data-keep', thisRound);
          $(this).parent().parent().find('.show-child-menu:not([data-keep="' + thisRound + '"])').removeClass('show-child-menu');
        });

        //$(document).ready(function() {
          // console.log('before');
          // setTimeout(function(){ console.log('after 1000 ms');}, 2000);


        //});

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.vc_tta-panel').removeClass('vc_active');


        $(document).on('click', '.hide-on-mobile a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 500);
        });

        // $(document).on('click', 'a[href="#1515614515231-54a7f569-b8d115160611617201516061894442"]', function (event) {
        //     event.preventDefault();
        //
        //     $('html, body').animate({
        //         scrollTop: $($.attr(this, 'href')).offset().top
        //     }, 0);
        // });
        // $(document).on('click', 'a[href="#1515614515262-6f572fee-528915160611617201516061894442"]', function (event) {
        //     event.preventDefault();
        //
        //     $('html, body').animate({
        //         scrollTop: $($.attr(this, 'href')).offset().top
        //     }, 0);
        // });


        // console.log('test');

        function initMap() {
          var latitude = $('.locationMapMap').data('latitude');
          var longitude = $('.locationMapMap').data('longitude');
          
          var uluru = {lat: latitude, lng: longitude};

          var map = new google.maps.Map(document.getElementById('locationMapMap'), {
            zoom: 4,
            center: uluru
          });
          var marker = new google.maps.Marker({
            position: uluru,
            map: map
          });
        }

        initMap()


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page





      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        var easingFn = function (t, b, c, d) {
        var ts = (t /= d) * t;
        var tc = ts * t;
        return b + c * (tc + -3 * ts + 3 * t);
        }
        var options = {
          useEasing: true,
          easingFn: easingFn,
          useGrouping: true,
          separator: ',',
          decimal: '.',
        };

        var options2 = {
          useEasing: true,
          easingFn: easingFn,
          useGrouping: true,
          separator: ',',
          decimal: '.'
        };

        var count = $('#myTargetElement').data('count');
        var demo = new CountUp('myTargetElement', 0, count, 0, 2.5, options);


        var count2 = $('#myTargetElement2').data('count');

        var demo2 = new CountUp('myTargetElement2', 0, count2, 0, 2.5, options2);
        if (!demo2.error) {
        //   demo2.start();
        } else {
          console.error(demo2.error);
        }

        if (!demo.error) {
          demo.start();
        } else {
          console.error(demo.error);
        }

        // $(window).scroll(function() {
        //  var hT = $('#myTargetElement').offset().top,
        //      hH = $('#myTargetElement').outerHeight(),
        //      wH = $(window).height(),
        //      wS = $(this).scrollTop();
        //  if (wS > (hT+hH-wH)){
        //    if (!demo.error) {
        //      demo.start();
        //    } else {
        //      console.error(demo.error);
        //    }
        // }
        //});

      $(window).scroll(function() {
       var hT = $('#myTargetElement2').offset().top,
           hH = $('#myTargetElement2').outerHeight(),
           wH = $(window).height(),
           wS = $(this).scrollTop();
       if (wS > (hT+hH-wH)){
         if (!demo2.error) {
           demo2.start();
         } else {
           console.error(demo2.error);
         }
      }
      });


      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
